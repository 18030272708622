import { ImageLoaderProps } from "next/legacy/image";



// NEXT_PUBLIC_IMAGE_ROOT="https://storage.googleapis.com/assets.prod.seacrush.com/public/dev"
export const endpointUrl = process.env.NEXT_PUBLIC_IMAGE_ROOT

const RESIZED_FILE = /.*\.webp?/

const googleBucketLoader = ({src, width, quality}: ImageLoaderProps) => {
    if (src[0] === "/") src = src.slice(1);

    // const params = [`w-${width}`];
    // if (quality) {
    //     params.push(`q-${quality}`);
    // }
    // const paramsString = params.join(",");

    const targetFile = RESIZED_FILE.test(src) && src.indexOf("__.") < 0 ? src.replace(".webp", `_${width}__.webp`) : src

    //console.log(`src: ${targetFile} ${width}`)

    return `${endpointUrl}/${targetFile}`
}

export default googleBucketLoader
