import React, {useState, useEffect, ReactChild} from "react";
import FormControl from "@mui/material/FormControl";
import Select, {SelectChangeEvent, SelectProps} from "@mui/material/Select";
import {addMonths, format} from "date-fns";
import {enUS} from "date-fns/locale";
import continents from "../generated/continents.json"

import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Grid from "@mui/material/Grid/Grid";

import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import {LoadingButton} from "@mui/lab";
import {useSelector, useDispatch} from "../store";
import {dateFormat} from "../lib/date-format";
import {setCriteriaContinent, setCriteriaPeriod, getTrips} from "../slices/trips-slice";
import {Box, useMediaQuery} from "@mui/material";
import {useTranslation} from "next-i18next";
import {useRouter} from "next-translate-routes/router";
import {styled, useTheme} from "@mui/material/styles";


const StyledFormControl = styled(FormControl)(({theme}) => ({
    backgroundColor: "#fff",
    [theme.breakpoints.down("md")]: {
        marginBottom: theme.spacing(3),
    },
}))

const StyledSelect = styled(Select)<SelectProps>(({theme}) => ({
    width: 290,
    display: "block",
    [theme.breakpoints.down("sm")]: {
        width: 290,
    },
}))


export const InitialSearch = () => {

    const theme = useTheme()

    const {t, i18n} = useTranslation();

    const isUpMd = useMediaQuery(theme.breakpoints.up("md"))

    const router = useRouter()

    const {criteria} = useSelector((state) => state.trips);

    const {selectedContinent, selectedPeriod} = criteria

    const selectedContinentSlug = (continents.filter((c: any) => c.continentId == selectedContinent).map((c: any) => c.slug[i18n.language].current))[0] || ""

    const dispatch = useDispatch()

    const handleCriteriaChanged = (e: SelectChangeEvent<unknown>) => {
        if (e.target.name === "diveContinent") {
            //TODO: re-implement
            // const translatedContinent = t(e.target.value);
            // const newPath = `/${match.params[0]}/${translatedContinent}/`.replace(
            //     "//",
            //     "/"
            // );
            // history.replace(newPath + history.location.search);
            dispatch(setCriteriaContinent(e.target.value as string));
        } else if (e.target.name === "divePeriod") {
            dispatch(setCriteriaPeriod(e.target.value as string));
            //setQuery({divePeriod: e.target.value});
        }

        dispatch(getTrips({language: i18n.language}));
    };

    //console.log('dive continent', diveContinent)

    const [submitted, setSubmitted] = useState(false);

    let timer = null;

    useEffect(() => {
        setSubmitted(false);
    }, []);


    const handleSubmit = (e: any) => {
        e.preventDefault()
        router.push("/diving", `/diving/${selectedContinentSlug}`, {unstable_skipClientCache: true, shallow: true})
    };

    return (
        <Box>
            <form onSubmit={handleSubmit} noValidate autoComplete='off'>
                <Grid
                    container
                    direction={isUpMd ? "row" : "column"}
                    alignItems={"stretch"}
                    alignContent={"center"}
                    wrap={"wrap"}
                    justifyContent={"center"}
                >
                    <Grid item>
                        <StyledFormControl variant={"filled"}>
                            <InputLabel htmlFor='diveContinent' shrink={true}>
                                {t("where-do-you-want-to-go")}
                            </InputLabel>
                            <StyledSelect
                                variant={"filled"}
                                disableUnderline
                                autoWidth
                                native
                                onChange={handleCriteriaChanged}
                                value={selectedContinent}
                                input={
                                    <FilledInput
                                        name='diveContinent'
                                        inputProps={{
                                            id: "diveContinent",
                                        }}
                                        sx={{
                                            backgroundColor: "#fff",
                                            height: 60,
                                            borderRadius: 0,

                                        }}
                                    />
                                }
                            >
                                <option value=''>{t("anywhere")}</option>
                                {continents.map((continent: any, idx: number) => {
                                    return <option key={idx}
                                                   value={continent.continentId}>{continent.title[i18n.language]}</option>
                                })}
                            </StyledSelect>
                        </StyledFormControl>
                    </Grid>
                    <Grid item>
                        <StyledFormControl variant={"filled"}>
                            <InputLabel htmlFor='divePeriod' shrink={true}>
                                {t("when-do-you-want-to-go")}
                            </InputLabel>
                            <StyledSelect
                                variant={"filled"}
                                disableUnderline
                                autoWidth
                                native
                                value={selectedPeriod}
                                onChange={handleCriteriaChanged}
                                input={
                                    <FilledInput
                                        name='divePeriod'
                                        inputProps={{
                                            id: "divePeriod",
                                        }}
                                        sx={{
                                            backgroundColor: "#fff",
                                            height: 60,
                                            borderRadius: 0,
                                        }}
                                    />
                                }
                            >
                                <option value=''>{t("period-anytime")}</option>

                                {[...Array(36)].map((x, i) => {
                                    const myDate = addMonths(new Date(), i);

                                    return <option key={i}
                                                   value={format(myDate, "yyyy-MMM", {locale: enUS}).toLowerCase()}>{dateFormat(myDate, "MMMM yyyy", i18n.language)}</option>
                                })}
                            </StyledSelect>
                        </StyledFormControl>
                    </Grid>
                    <Grid item>
                        <LoadingButton
                            type='submit'
                            variant='contained'
                            color='secondary'
                            loading={submitted}
                            sx={{
                                boxShadow: "none",
                                height: 60,
                                // width: "90%",
                                margin: 0,
                                fontSize: "1.5rem",
                                // fontSize: "30px",

                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: 200,

                                },
                                px: {
                                    xs: 0,
                                    sm: 2,
                                    md: 4
                                },

                                pt: 1,
                                whiteSpace: "nowrap",
                                // "& :disabled": {
                                //     backgroundColor: "#dc7172",
                                // },
                            }}
                        >
                            &nbsp;{t("initial-search-button")}
                            <KeyboardArrowRight
                                style={{fontSize: 36, margin: 0, paddingBottom: 4}}
                            />
                        </LoadingButton>

                    </Grid>
                </Grid>
            </form>
        </Box>
    );
}
