import {useTranslation} from "next-i18next";
import sampleDestinations from "../../generated/sample-destinations.json"
import tripDatabase from "../../generated/trip-database.json";
import Image from "next/legacy/image";
import React, {useEffect, useRef, useState} from "react";
import Hidden from "@mui/material/Hidden";
// @ts-ignore
import SwipeableViews from "@pjtsearch/react-swipeable-views";
import useWidth from "../../hooks/use-width";
import googleBucketLoader from "../../lib/google-bucket-loader";
import NextLink from "next-translate-routes/link";
import {useLocale} from "../../hooks/use-locale";
import {Typography, Box} from "@mui/material";
import {setSelectedTripId} from "../../slices/user-session-slice";
import {useDispatch} from "../../store";
import {useTheme} from "@mui/material/styles";

export const SampleDestinations = () => {

    const {t, i18n} = useTranslation();

    const locale = useLocale()

    const theme = useTheme();

    const ref = useRef<HTMLDivElement>(null);

    const [currentNav, setCurrentNav] = useState(0);

    const [playing, setPlaying] = useState(false);

    const width = useWidth()

    const isMobile = width == "xs"

    const dispatch = useDispatch()


    useEffect(() => {
        setPlaying(true);
    }, []);

    useEffect(() => {
        let interval: any = null;

        if (playing) {
            interval = setInterval(() => {
                setCurrentNav((currentNav + 1) % sampleDestinations.length);
            }, 3000);
        } else if (!playing) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [playing, currentNav]);

    const onNavEnter = (idx: number) => {
        //console.log(`onNavEnter(${idx})`)
        setPlaying(false);
        setCurrentNav(idx);
    };

    const onNavLeave = (idx: number) => {
        //console.log(`onNavLeave(${idx})`)
        setPlaying(true);
    };

    const updateCurrentSlide = (index: number) => {
        if (currentNav !== index) {
            setCurrentNav(index);
        }
    };


    const containerHeight = ref.current ? ref.current.offsetWidth * (2 / 3) : 0;
    // const containerHeight = 0;


    return (
        <div style={{position: "relative"}} ref={ref}>

            <SwipeableViews
                index={currentNav}
                disableLazyLoading={true}
                containerStyle={{height: containerHeight}}
                // @ts-ignore
                onChangeIndex={(index, indexLast) => updateCurrentSlide(index)}
                axis={isMobile ? "x" : "y"}
                resistance
            >
                {sampleDestinations.map((sampleTrip, idx) => {

                    // @ts-ignore
                    const sampleName = sampleTrip.sampleName[i18n.language];

                    const tripData = tripDatabase[sampleTrip.tripId as keyof typeof tripDatabase];
                    const slugs = tripData.slugs[locale === "fr" ? "fr" : "en"]
                    return (
                        <div
                            key={idx}
                            style={{position: "relative"}}
                        >
                            <Image src={`/content/trips/${sampleTrip.tripId}/${sampleTrip.imageName}`}
                                   alt={sampleName}
                                   loader={googleBucketLoader}
                                   loading={"lazy"}
                                   style={{
                                       transition: "opacity 250ms linear",
                                       opacity: idx === currentNav ? 1 : 0
                                   }}
                                   layout={"responsive"}
                                   placeholder={"blur"}
                                   blurDataURL={sampleTrip.blurDataURL}
                                   width={1200}
                                   height={800}/>
                            <Hidden mdUp>
                                <NextLink passHref href={{
                                    pathname: "/diving/[countrySlug]/[tripSlug]",
                                    query: {countrySlug: slugs.countrySlug, tripSlug: slugs.tripSlug}
                                }}

                                >
                                    <Typography
                                        component={"div"}
                                        sx={{
                                            textDecoration: "none",
                                            fontSize: "1.3rem",
                                            fontStyle: "italic",
                                            padding: 1,
                                            position: "absolute",
                                            backgroundColor: "rgba(128,128,128,0.5)",
                                            top: 16,
                                            left: 0,
                                            zIndex: 1,
                                            color: "white",
                                            "&:hover": {
                                                color: "white",
                                                textDecoration: "none",
                                            }
                                        }}
                                        onClick={() => dispatch(setSelectedTripId(sampleTrip.tripId))}
                                    >
                                        {sampleName}
                                    </Typography>
                                </NextLink>
                            </Hidden>
                        </div>
                    );
                })}
            </SwipeableViews>
            <div
                style={{
                    height: 2,
                    width: "100%",
                    backgroundColor: "#f6f6f6",
                    position: "absolute",
                    zIndex: 1,
                    top: 0,
                }}
            ></div>

            {containerHeight !== 0 && (
                <Box sx={{
                    display: {
                        xs: "none",
                        md: "block"
                    },
                    position: "absolute",
                    height: 200,
                    width: 300,
                    left: -250,
                    bottom: 50,
                }}>
                    {sampleDestinations.map((sampleTrip, idx) => {

                        const tripData = tripDatabase[sampleTrip.tripId as keyof typeof tripDatabase];
                        const slugs = tripData.slugs[locale === "fr" ? "fr" : "en"]

                        // @ts-ignore
                        const sampleName = sampleTrip.sampleName[i18n.language];

                        return (
                            <NextLink
                                key={idx}
                                href={{
                                    pathname: "/diving/[countrySlug]/[tripSlug]",
                                    query: {countrySlug: slugs.countrySlug, tripSlug: slugs.tripSlug}
                                }}
                                legacyBehavior
                                passHref
                            >
                                <Typography
                                    component={"a"}
                                    onClick={() => dispatch(setSelectedTripId(sampleTrip.tripId))}
                                    onMouseEnter={(e) => {
                                        onNavEnter(idx);
                                    }}
                                    onMouseLeave={(e) => {
                                        onNavLeave(idx);
                                    }}
                                    fontWeight={500}
                                    style={{
                                        fontSize: idx === currentNav ? "1.2rem" : "1rem",
                                    }}
                                    sx={[
                                        {
                                            textDecoration: "none",
                                            textAlign: "right",
                                            display: "block",
                                            fontStyle: "italic",
                                            lineHeight: "2.0rem",
                                            paddingRight: "80px",
                                            letterSpacing: -0.25,
                                            color: "#707070",
                                            cursor: "pointer",
                                            transition: "ease all .3s",
                                            position: "relative",
                                            "&:after": {
                                                color: "#fff",
                                                content: "\"\"",
                                                position: "absolute",
                                                bottom: "0.3rem",
                                                width: 0,
                                                height: "18px",
                                                margin: "0px 0 0",
                                                transition: "all 0.4s ease-in-out",
                                                opacity: 0,
                                                left: "235px",
                                                // backgroundColor: 'gray',
                                                backgroundImage: "url(\"/images/home/sample-indicator.svg\")",
                                                backgroundRepeat: "no-repeat",
                                                // backgroundSize: 'cover',
                                                backgroundPosition: "right",
                                            }
                                        },
                                        idx === currentNav && {
                                            letterSpacing: 0.5,
                                            color: theme.palette.primary.main,
                                            "&:after": {
                                                width: 99,
                                                opacity: 1,
                                            },
                                        }
                                    ]
                                    }
                                >
                                    {sampleName}
                                </Typography>
                            </NextLink>
                        )
                    })}
                </Box>
            )}
        </div>
    )
}
