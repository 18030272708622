import React, {FC} from "react";
import {useTranslation} from "next-i18next";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {CardAccountDetails as RegisterIcon} from "mdi-material-ui";
import {UserAvatar} from "../user-avatar";
import {Paper, Typography} from "@mui/material";
import {setShowAuthWall} from "../../slices/user-session-slice";
import {useDispatch} from "../../store";
import {SlimUserInterface} from "seacrush-core"

interface RecentUsersBoxProps {
    recentUsers: SlimUserInterface[]
}

export const RecentUsersBox:FC<RecentUsersBoxProps> = ({recentUsers}) => {
    const {t, i18n} = useTranslation();

    const dispatch = useDispatch();

    const handleLoginClicked = () => {
        dispatch(setShowAuthWall(true));
    };

    return (
        <Paper
            elevation={8}
            sx={{
                maxWidth: 580,
                margin: "0 auto",
                mt: 6,
                mb: 6,
                position: "relative",
                minHeight: 130,
                // backgroundColor: "red"
            }}>

            <img src={`/images/home/sign_up_${i18n.language}.svg`} width='86' height='87'
                 style={{
                     position: "absolute",
                     left: -1,
                     top: -3
                 }} alt='beta'/>


            <Typography variant={"subtitle1"} color={"text"} component={"div"}
                        sx={{textAlign: "center", pt: {xs: 5,sm: 3}, px:5, mb: 1, fontWeight: 400}}>{t("beta-box-title")}</Typography>

            <Grid
                container
                alignItems={"center"}
                spacing={0}
                justifyContent={"center"}
                style={{minHeight: 85}}
                direction={"row"}
            >
                <Grid item sx={{minWidth: 216, minHeight: 93}}>
                    <div style={{paddingLeft: 14}}>
                        {recentUsers.map((recentUser: SlimUserInterface, idx) => {
                            return (
                                <UserAvatar
                                    key={idx}
                                    slimUser={recentUser}
                                />
                            );
                        })}
                    </div>
                </Grid>
                <Grid item>
                    <Button
                        sx={{
                            mx: 2,
                            mb: 3, mt: {
                                xs: 1,
                                sm: 3
                            }
                        }}
                        variant="contained"
                        color="primary"
                        onClick={handleLoginClicked}
                        startIcon={<RegisterIcon style={{marginBottom: 4}}/>}
                    >
                        {t("register")}
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
}
