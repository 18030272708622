import React from "react"
import RegisterIcon from "mdi-material-ui/CardAccountDetails"
import {useTranslation} from "next-i18next"
import Box from "@mui/material/Box";
import {Hidden, Button, Grid, Typography} from "@mui/material";
import Image from "next/legacy/image";
import NextLink from "next-translate-routes/link";
import {setShowAuthWall} from "../../slices/user-session-slice";
import googleBucketLoader from "../../lib/google-bucket-loader";
import {useDispatch} from "../../store";


export const HomeResponsible = () => {

    const {t, i18n} = useTranslation();

    const siteLang = i18n.language


    const dispatch = useDispatch()

    const samples: any = [
        {
            name: {
                en: "responsible diver in maldives",
                fr: "plongeuse responsable aux Maldives"
            },
            url: "/images/home/responsible/mobile-responsible1"
        },
        {
            name: {
                en: "responsible diver looking at a buddha",
                fr: "plongeur responsable devant un bouddha"
            },
            url: "/images/home/responsible/mobile-responsible2"

        },
        {
            name: {
                en: "free-diver with a whale shark",
                fr: "apnéiste avec un requin baleine"
            },
            url: "/images/home/responsible/mobile-responsible3"
        },
        {
            name: {
                en: "Responsible diving",
                fr: "Plongée responsable"
            },
            url: "/images/home/responsible/mobile-responsible0"
        }
    ]

    const settings = {
        vertical: false,
        autoplay: true,
        autoplaySpeed: 4000,
        dots: false,
        arrows: false,
        pauseOnHover: true,
        infinite: true,
        speed: 500,
        adaptiveHeight: false,
        slidesToScroll: 1,
        centerMode: false,
        waitForAnimate: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    vertical: false,
                    centerMode: false,
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    vertical: false,
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 10000,
                settings: {
                    vertical: true,
                    slidesToShow: 1
                },
            }]
    }


    const loginClicked = () => {
        dispatch(setShowAuthWall(true))
    }

    return <>
        <Grid container columnSpacing={2} direction={"row"} alignItems={"end"} sx={{mt: 12}}>
            <Grid item md={4} xl={5} sx={{
                pl: 0,
                display: {
                    xs: "none",
                    md: "block"
                }
            }}
            >
                <Image
                    loader={googleBucketLoader}
                    width={920}
                    height={520}
                    layout={"responsive"}
                    placeholder={"blur"}
                    blurDataURL={"data:image/webp;base64,UklGRnYBAABXRUJQVlA4IGoBAADwBgCdASogABIAPm0qkEWkIqGYBABABsS2AE6ZdnJPDz2qc9vAOfAFq2fTRbSQ2H4VNojqt/JYJ9TYV7eDqhiAAP6mAd3vUBGxaRx/5kRsMyagpff3mThgePlxueiJUpofRyT/3gvMaM2jXh7zfa5g9UbKekZyu8i3eAhq+ze0GiM1Lj/G2hJ5lRY2l+mtfM7+5e7+XlHNgEDhndR51IVWbOfEilJlaOy3b5mCVWPdP9L+Wr0UoEPPEKZXeaCFplHvWX9w9HJAXyCUthrCwnNbMv7OaV5zj1azwjR7u/q+5lGdlm1xLp560eiO8DtmNDEsdpRuEgNbzzm1CW/zQDWGu4czU9fY1AevUF9eL130Eoo4/35Vj/Y36doM2v8N7HJLkWyI/cps5FfqPCbq5AhbaidZIXAgSJEf/hr9VFGnS5G/TjJlLx7R3egebHq3opvpzOaZqJMHXBfqsqlVtkPhwOx9TAgFzkAAAA=="}
                    src='/images/home/responsible/responsible1_920.webp'
                    sizes='20vw'
                    alt={samples[0].name[siteLang]}/>
            </Grid>
            <Grid item md={2} xl={2} sx={{
                px: 0, display: {
                    xs: "none",
                    md: "block"
                }
            }}
            >
                <Image
                    loader={googleBucketLoader}
                    src='/images/home/responsible/responsible0_380.webp'
                    width={380}
                    height={562}
                    layout={"responsive"}
                    placeholder={"blur"}
                    blurDataURL={"data:image/webp;base64,UklGRrADAABXRUJQVlA4IKQDAADQDwCdASogAC8APlkgjEWjoiEdVAA4BYS2AE6ZQjwmQJSf65rCJAoTG258VXqS7w1vGVmCNBM6PzG+EZr4a+QI4GTC4gNIF8H6BXNo9F+wL+ufVt/bz2bv2AcR8DQqDzW32+AV2/ap1nEvM7EzNGFnzc+J+n/D5QA7ygMR2++G76aXjwjBTYAA/v9NgF3Va2jVE0/YVpiTEiNpHjw8atmgVCN5Lmd+6+l/45+T69f+d9qDZ6T1VGPTTac+B2hH/3feW4Go6ddFCb7nu8vX452uWVWtMuEk6cNeWyfiM3yXMPFV9x3c9Wrnha6QrwVLsEw0/0kBJPWHS+//AQwsIjtru+jArvx0H/yzaQo3z7nCTb3T+4ltyoe2fO7U5fgcvYjsnLzkLnG1pGnzTy0ODkeBB+u7g68r2y0xZdUhp5qBBxOX4ijtFvZ3+UvNFpP2aR8m7E++ov6xmq+WCWQqFVYP55gLFDDpEonXxUsCkWOD/Ax5zi3VE2iqbvTTQOev1RBDNca0Uxgq5gkoTGPJRFoKGVTiXN9z5vZMO4BoqZ5VxlELmenOvYykgpZkXX5lTPiGMSCMd3wSXcNgjSmvejVVs6eMRDJIcCjRZ9K+Uwcro+kbHuzD5ughnOoB+b8mJG6CRF0s//NgPOO9GORem4bPEcIU41A20m8kR/ZaP+tsze6qTjtpt02/lpfosenWg/BelqVwYA/E9pgnuxsB56tsx7FsGe6HWJMjrg05/4rxcPvgeyxFAtOgq+9ggRhvxbU18515/NubdotNCUrMWG2DWnZm799IxoSWsX34yxwRxj+INOfJ5arQjGQzM5kvlFj+Dthl3PMZne/7cSjmHZIhxlYHkzGRai2hnV1mbeNWk5z/itZSf3njBplOnzgYRPB4x1F5nn1OF0/38t/yl2hDybgg3up5ydESeXaCfDN4w8LTcwumvI+j4/InctZ51bA7mJsv0EzOUKoZKUP8Z+GWrJXlAzhP0ZfUQjRBjMbrHIfPZqyo/iES47UdPCu6swRIkE4ZkEYJPaBAHqB4fL4wnPAAxa1zcQ6Qcp4jUkhvfuPpYAkgeEWM8pJ67/44jFhngAj8H+hIVtJprtavThqX6ID3tUt19IfvYAzRa21TOuRukKXXnLlcRez8Xshlgaepi8DAOdugkh2z+i4fYrgAnY395Ww8hunbI2eyIQK8xfJyWR6Mq/68oTPlA6EZcfztOQ+IiRLjXb2FG3BJUDdcF7wAAA=="}
                    sizes='20vw'
                    alt={samples[3].name[siteLang]}/>
            </Grid>
            <Grid item md={6} xl={5} alignSelf={"end"}
                  sx={{pb: 0}}
            >

                <Typography variant={"h3"} sx={{fontWeight: 400, lineHeight: 1.1, mb: 1}}
                            dangerouslySetInnerHTML={{__html: t("with-responsible-divers")}}/>

                <Hidden mdUp>
                    {
                        samples.filter((sample: any, idx: number) => idx === 0).map((sample: any, idx: number) => {
                            return <Box key={idx} style={{position: "relative"}} my={1}>

                                <Image
                                    loader={googleBucketLoader}
                                    width={1200}
                                    height={800}
                                    layout={"responsive"}
                                    sizes='(min-width: 1200px) 30vw, (min-width: 992px) 50vw, 100vw'
                                    src={`${sample.url}_1200.webp`}
                                    alt={sample.name[siteLang]}
                                />


                            </Box>
                        })
                    }
                </Hidden>
                <Typography variant={"body1"} color={"textSecondary"}
                            dangerouslySetInnerHTML={{__html: t("responsible-divers-desc")}}/>
            </Grid>
        </Grid>

        <Grid container alignItems={"start"} sx={{pt: 2}} columnSpacing={2}

        >
            <Grid item md={2} xl={2} sx={{
                px: 0,
                display: {
                    xs: "none",
                    md: "block"
                }
            }}
            >
                <Image
                    loader={googleBucketLoader}
                    width={380}
                    height={570}
                    layout={"responsive"}
                    sizes='20vw'
                    placeholder={"blur"}
                    blurDataURL={"data:image/webp;base64,UklGRm4CAABXRUJQVlA4IGICAABwDACdASogADAAPm0qkEWkIqGYBABABsS2AE6ZQjn30n8ZvYEoD9MxUuABt+txL5//UzbzNZrJ6acDcR0gTLu8ZWH+pNGHBfPXI/RPpatruQeiPpzpCGaQu50yskezr4tmhrxUPH7Ky3juAAAA/vZMEdMcmMA8HtGjSGy+ef86iMyTCL9JMIimkjDEw+R6g77C329aLf5KGsNDS//3aea28sRdZqh2Du4sJv/1y4h3NPHaEXCR9WOG8NHzbpk/k0qwSRWjyp4v5q0jI3TfZmj1Uj/JUVXVXBc1gGmJ11V6r+a+ZvceYsKjZwdn0IGum9Wlk7v2ZR55wo87g/WlnrLBa6QLUNv+U7AH/Y4EkuJcuu8yQWaFuG2pAXQlNlpemvzNtxaZgZj+jxAgJW44Q20ZVyd7PdwoHLLq+15tIbP4qk/bkqhUIrzuZLJ5M6jGTkfiHQ1PDZ+q/XOqsKXwVenLsRvrTpM9fu1f4XPm5Axwd0hGIkNfYDcgxAJtw8xs7AKvQd2RGsw3YV+iImXdAREn+ZdLUMMseN1+AG/ksL+LQtG/gfY6a1sOfXLSr48DehK7apBOeNeuKR5NACPV1HijwPkJ1uwG1FjxbEY/WRR8UGye+jzUi0qwHpdQue2cWqbW4qUQFv/h/Yny/fedpiqk1t5FDavGde3z+yIMJZl4IpRqvSUOXlbDzH/YPRjN/bhaai1H46CGtHkGR0+ecpG9wK9VzxLf/QeV5IjIieei8DFuayDv84vRKhhBnKlrUUt2Qc9bEnRwG2au9UkmePtlz0Q6/pxQ5bmSXfN4K4LqAAAA"}
                    src='/images/home/responsible/responsible2_380.webp'
                    alt={samples[1].name[siteLang]}/>
            </Grid>
            <Grid item
                  md={3} xl={4} sx={{
                display: {
                    xs: "none",
                    md: "block"
                }
            }}
            >
                <Image
                    loader={googleBucketLoader}
                    width={350}
                    height={237}
                    src='/images/home/responsible/responsible3_350.webp'
                    layout={"responsive"}
                    placeholder={"blur"}
                    blurDataURL={"data:image/webp;base64,UklGRvoAAABXRUJQVlA4IO4AAADQBQCdASogABYAPm0wk0ckIqGhKAqogA2JYgCdL5A/k8pMhXOSCi+XtAAOlSdYdKc3GBoXGBYAAP78SI/WNnj9wT7Veck9X4G/LyKzMSo6moiFyoCUXfN+iyz7VUYLn0/3EIqei2Hc/UzQJHiDPWqftfId/b1uFrzNbnA5KCmiRQ45X6l6+GY4z2upVORmK9k9xWbk5uderBSt9Qo1KDAvgGO/ngnm47fFnUbcMLI0vBnCc9E/uKRm1eM/zk5Vv9zKlNP7BU1jV1bi90GaOWFe94gYRatJOPn2xrN3vnhbDtUV5bmjVbEQvRsx6WAA"}
                    sizes='20vw'
                    alt={samples[2].name[siteLang]}/>
            </Grid>
            <Grid item
                  xs={false}
                  md={1} sx={{
                display: {
                    xs: "none",
                    md: "block"
                }
            }}
            >
                &nbsp;
            </Grid>
            <Grid item
                  xs={12}
                  md={6} xl={5} sx={{
                textAlign: {
                    xs: "center",
                    md: "left"
                }
            }}
            >
                <NextLink href={"/concept"} passHref legacyBehavior>
                    <Button variant='outlined'
                            color='primary'
                            sx={{
                                mt: {
                                    xs: 1,
                                    md: 0
                                }
                            }}
                            title={t("why-responsible-diving-is-important")}
                    >{t("the-concept")}</Button>
                </NextLink>
            </Grid>
        </Grid>


        <Grid container direction={"row"} sx={{mt: 8, mb: 8}}>
            <Grid item
                  xs={12}
                  md={4}
                  alignSelf={"center"}
            >
                <Typography variant={"h3"} sx={{fontWeight: 400, lineHeight: 1.1, mb: 1}}
                            dangerouslySetInnerHTML={{__html: t("meet-new-friends")}}/>

                <Hidden mdUp>
                    <Image
                        loader={googleBucketLoader}
                        width={1200}
                        height={578}
                        sizes='80vw'
                        placeholder={"blur"}
                        blurDataURL={"data:image/webp;base64,UklGRioBAABXRUJQVlA4IB4BAACQBQCdASogAA8APm0skkWkIqGYBABABsSgCdIEzmAMgFMm8kqLQSiVFpdzfOU09ve2SReSAAD+W/Ziuf9vfw9WJ4bu26kx8xLj+k1hiAPyvtnQjp1khchKv4fn95gaUduMw9UJHEWkN4FTmg0gLXuoGTvW2/lwX9MU7Quw1JxHMSIsnNNip4GV1h8gI3cmouBynEF2O8ZJ8e0P/U5sYO7BtE2R/p88twfYj8t/zZpbuNtXKNG//Pf+GQVq4rH6Ppd/595Rrg/xU9Nz87HZm5z4gnwlFeTieczXjwYZvV096WR++oMDKyDXrK2pisQ5MdjFzXXEUlsSDXruX3jj8b3zt5rxxTfWyH4VZ75tDVIXh+W5ovqvNUL24nzIAAAA"}
                        src='/images/home/meet-new-friends_1200.webp'
                        layout={"responsive"}
                        alt={t("meet-new-friends")}/>
                </Hidden>

                <Typography variant={"body1"} color={"textSecondary"}
                            sx={{mt: 1}}
                            dangerouslySetInnerHTML={{__html: t("meet-new-friends-cta")}}/>

                <Button variant='contained'
                        sx={{
                            mt: 4,
                            marginLeft: {
                                xs: "auto",
                                md: 0
                            },
                            marginRight: {
                                xs: "auto",
                                md: 0
                            }
                        }}
                        color='secondary'
                        onClick={loginClicked}
                        startIcon={<RegisterIcon style={{marginBottom: 4}}/>}
                >

                    {t("register")}
                </Button>
            </Grid>
            <Grid item
                  xs={false}
                  md={8} lg={8}
                  sx={{
                      pt: 3,
                      display: {
                          xs: "none",
                          md: "block"
                      }
                  }}

            >
                <Image
                    loader={googleBucketLoader}
                    width={1200}
                    height={578}
                    placeholder={"blur"}
                    blurDataURL={"data:image/webp;base64,UklGRioBAABXRUJQVlA4IB4BAACQBQCdASogAA8APm0skkWkIqGYBABABsSgCdIEzmAMgFMm8kqLQSiVFpdzfOU09ve2SReSAAD+W/Ziuf9vfw9WJ4bu26kx8xLj+k1hiAPyvtnQjp1khchKv4fn95gaUduMw9UJHEWkN4FTmg0gLXuoGTvW2/lwX9MU7Quw1JxHMSIsnNNip4GV1h8gI3cmouBynEF2O8ZJ8e0P/U5sYO7BtE2R/p88twfYj8t/zZpbuNtXKNG//Pf+GQVq4rH6Ppd/595Rrg/xU9Nz87HZm5z4gnwlFeTieczXjwYZvV096WR++oMDKyDXrK2pisQ5MdjFzXXEUlsSDXruX3jj8b3zt5rxxTfWyH4VZ75tDVIXh+W5ovqvNUL24nzIAAAA"}
                    src='/images/home/meet-new-friends_1200.webp'
                    layout={"responsive"}
                    alt={t("meet-new-friends")}/>
            </Grid>
        </Grid>


    </>

}
